import React, { useEffect, useState } from 'react';
import JobQualityChecksTable from '../../../../job-view-page/components/job-content/quality-check/JobQualityChecksTable';
import { getJobQualityChecksThunk } from '../../../../../store/thunks/jobs/jobThunks';
import { useAppDispatch } from '../../../../../store/hooks';
import QualityCheckModal
  from '../../../../job-view-page/components/job-content/quality-check/quality-check-modal/QualityCheckModal';
import { setJobQualityChecks } from '../../../../../store/slices/jobsSlice';

type JobLineExpandedQualityCheckProps = {
  jobId: number,
}

const JobLineExpandedQualityCheck: React.FC<JobLineExpandedQualityCheckProps> = ({ jobId }) => {
  const dispatch = useAppDispatch();

  const [isRunCheck, setIsRunCheck] = useState(false);
  const [interactedId, setIsInteractedId] = useState<number | undefined>(undefined);

  useEffect(() => {
    jobId && dispatch(getJobQualityChecksThunk({ jobId }));
    // eslint-disable-next-line
  }, [jobId]);

  useEffect(() => {
    return () => {
      dispatch(setJobQualityChecks([]));
    };
    // eslint-disable-next-line
    }, []);
  return (
    <>
      <QualityCheckModal
        isVisible={isRunCheck}
        onCancel={() => {
          setIsRunCheck(false);
          setIsInteractedId(undefined);
        }}
        readMode
        interactedId={interactedId}
      />
      <JobQualityChecksTable
        openQualityCheckDetails={(checkId: number) => {
          setIsInteractedId(checkId);
          setIsRunCheck(true);
        }}
      />
    </>
  );
};

export default JobLineExpandedQualityCheck;
