import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { DictionaryItem, NumberFunctionType } from '../../../core/types/coreTypes';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import {
  StockPartReqTableLineType,
  StockPartRequestLineActionType,
} from '../../stock-part-requests/types/stockPartRequestTypes';
import Checkbox from '../../../core/components/checkbox/Checkbox';

type StockPartRequestLineProps = {
  hiddenKeys: string[],
  nowrapKeys: string[],
  renderCellValue: (key: string, value: unknown, unitOfMeasure: number) => React.ReactNode | string | number,
  el: StockPartReqTableLineType,
  actionsRender: (status: string, sourceId: number) => StockPartRequestLineActionType[],
  deletedStatus?: DictionaryItem,
  archivedStatus?: DictionaryItem,
  allowedToManage?: boolean,
  isCheckboxRequired?: boolean,
  checked?: boolean,
  checkRowHandle?: NumberFunctionType,
}

const StockPartRequestLine: React.FC<StockPartRequestLineProps> = ({
  hiddenKeys,
  nowrapKeys,
  renderCellValue,
  el,
  actionsRender,
  deletedStatus,
  archivedStatus,
  allowedToManage,
  isCheckboxRequired,
  checked,
  checkRowHandle,
}) => {
  const disabledActions = !allowedToManage
      || el.childLines.length > 0
      || el.status?.toLowerCase() === archivedStatus?.label?.toLowerCase()
      || !!(el.status && actionsRender(el.status, el.id).length === 0);
  return (
    <Tr
      className={classNames({ 'suitableLine': el.status && el.status.toLowerCase() === deletedStatus?.label?.toLowerCase() })}
    >
      {isCheckboxRequired && <TdNew>
        <Checkbox
          checked={checked}
          onChange={() => {
            if (checkRowHandle) {
              checkRowHandle(el.id);
            }
          }}
          disabled={!el.canPurchaseOrderBeGenerated}
          table
        />
      </TdNew>}
      {Object.entries(el).map(([key, value]) => {
        if (hiddenKeys.includes(key)) return null;
        return <TdNew key={`${key}-${value}`} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
          {renderCellValue(key, value, el.unitOfMeasure)}
        </TdNew>;
      })}
      <TdNew action>
        <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          menuItems={actionsRender(el.status, el.id)}
          disabled={disabledActions}
        />
      </TdNew>
    </Tr>
  );
};

export default StockPartRequestLine;
