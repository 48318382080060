import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, DownloadUriResponse, emptyPaging, LinksFiltersType, NumberFunctionType, ResponseResult,
  ResponseSingleResult, ServerErrorType, StringFunctionType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  GetRectificationResponseType,
  RectificationSummaryResponse,
} from '../../../rectifications/dashboard/types/rectificationsTypes';
import {
  setCurrentArea,
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { rectificationsAPI } from '../../../api/rectificationsApi';
import {
  PatchRectificationDto, RectificationEventDtoBase,
  RectificationLinksResType,
} from '../../../rectifications/rectification-view-page/types/rectificationViewTypes';
import { RootState } from '../../store';
import { setRectificationTimeline, setRectificationTimelineFilters } from '../../slices/rectificationsSlice';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import {
  AfterLinesActionsParams,
  BulkPatchReqLinesDataType,
  CommonCreatePartLineReqDto,
  CreatePartRequestPartsKitLine,
  CustomLineReqType,
  ExtendedTimelineFilters,
  PartReqLinesFilters,
  PartRequestLineDto,
  PatchPartReqLineDto,
} from '../../../common/types/commonTypes';
import { coreAPI } from '../../../api/coreAPI';
import { stockApi } from '../../../api/stockApi';
import { PartDtoType, StockFiltersType } from '../../../stock/search/types/stockSearchTypes';
import { PartsKitsDto, PartsKitsFiltersType } from '../../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { UserEventRectification } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';
import { generateEmailAndNames } from '../../../common/utils/generateEmailAndNames';

export const getRectificationTimelineThunk = createAsyncThunk<
ResponseResult<RectificationEventDtoBase[]>, {
  rectificationId: number, filters: ExtendedTimelineFilters,
}>(
  'get/RectificationTimeline',
  async ({
    rectificationId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationTimeline(rectificationId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const fetchRectificationByIdThunk = createAsyncThunk<ResponseSingleResult<GetRectificationResponseType> | null,
{ rectificationId: number }>(
  'get/RectificationById',
  async ({ rectificationId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const responseBa = await coreAPI.fetchBusinessAreas();
      const response = await rectificationsAPI.fetchRectificationById(rectificationId);
      const baList = responseBa.data.data.items;
      const allowedBA = baList.filter((el) => el.isAllowed);
      const { businessArea } = response.data.data.rectification;
      if (allowedBA.find((ba) => ba.value === businessArea)) {
        dispatch(setCurrentArea(businessArea));
      } else {
        dispatch(setCurrentArea(allowedBA[0].value));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateRectificationPdfThunk = createAsyncThunk<DownloadUriResponse, {
  id: number,
}>(
  'get/Rectification/PDF',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await rectificationsAPI.exportRectificationToPdf(id);
      downloadCsv(res.data.data.downloadUri);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateRectificationEmailPdfThunk = createAsyncThunk<DownloadUriResponse | null, {
  id: number,
  closeModal: VoidFunctionType,
  selectedContacts: { id: number, name: string, email: string }[],
  setEmailHref: StringFunctionType,
}>(
  'get/Rectification/EmailPDF',
  async ({
    id, closeModal, selectedContacts, setEmailHref,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const {
        data: { data: { allItemIds } },
      } = await rectificationsAPI.fetchRectificationContacts(id, emptyPaging.page, emptyPaging.pageSize, true);
      const filteredContacts = selectedContacts.filter(({ id }) => allItemIds.includes(id));
      if (!filteredContacts.length) {
        closeModal();
        return null;
      }
      const { selectedNames, selectedEmails } = generateEmailAndNames(filteredContacts);
      const res = await rectificationsAPI.exportRectificationToPdf(id);
      const { downloadUri } = res.data.data;
      const { shared: { rectificationDetails } } = getState() as RootState;
      const rectificationNumber = rectificationDetails?.rectification.rectificationNumber;
      const subject = 'Vanliners rectification details';
      const encodedLink = encodeURIComponent(downloadUri.replace(/ /g, '%20'));
      // eslint-disable-next-line max-len
      const emailBody = `Dear ${selectedNames},%0D%0A%0D%0APlease find below a link to your rectification reference ${rectificationNumber}:%0D%0A%0D%0A${encodedLink}%0D%0A%0D%0AIf you require anything further, please get in touch.`;
      setEmailHref(`mailto:${selectedEmails}?subject=${subject}&body=${emailBody}&content-type=text/plain`);
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationSummaryThunk = createAsyncThunk<ResponseSingleResult<RectificationSummaryResponse> | null,
{ rectificationId: number }>(
  'get/RectificationSummary',
  async ({ rectificationId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationSummary(rectificationId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationPartRequestLines = createAsyncThunk<
ResponseResult<PartRequestLineDto[], { allItemIds: number[] }>,
{ partRequestId: number, filters: PartReqLinesFilters }
>('get/RectificationPartRequestLines', async ({ partRequestId, filters }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.fetchPartRequestLines({ ...filters, partRequestIds: [partRequestId] });
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

const afterLinesInteractionsHandle = (params: AfterLinesActionsParams, withoutSummary?: boolean) => {
  const {
    id, partRequestId, partRequestLinesFilters, dispatch,
  } = params;
  dispatch(fetchRectificationByIdThunk({ rectificationId: id }));
  partRequestLinesFilters && dispatch(getRectificationPartRequestLines({
    partRequestId,
    filters: partRequestLinesFilters,
  }));
  !withoutSummary && dispatch(getRectificationSummaryThunk({ rectificationId: id }));
};

export const patchRectificationThunk = createAsyncThunk<ResponseSingleResult | null,
{
  rectificationId: number,
  data: Partial<PatchRectificationDto>,
  partRequestId?: number,
  onClose?: VoidFunctionType,
  handleJobTypeError?: NumberFunctionType
  openReasonModal?: VoidFunctionType,
  setInitValue?: VoidFunctionType,
}>('patch/Rectification', async ({
  rectificationId, data, partRequestId, onClose, handleJobTypeError, openReasonModal, setInitValue,
}, { dispatch, getState, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    let isReasonRequired = false;
    if ((data.enableDiscounts !== undefined || data.rectificationDate) && partRequestId && !data.partRequestLineActionReason) {
      const {
        data: { data: { isCostChanged } },
      } = await stockApi.getPartRequestSummaryReview({
        id: partRequestId,
        priceDate: data.rectificationDate || undefined,
        enableDiscounts: data.enableDiscounts === null ? undefined : data.enableDiscounts,
      });
      isReasonRequired = isCostChanged;
    }
    if (isReasonRequired && openReasonModal && !data.partRequestLineActionReason) {
      dispatch(setLoading(false));
      openReasonModal();
      return null;
    }
    const response = await rectificationsAPI.patchRectification(rectificationId, data);
    const {
      rectifications: { rectificationTimelineFilters, partRequestLinesFilters },
    } = getState() as RootState;
    if (rectificationId && partRequestId) {
      const params = {
        id: rectificationId,
        partRequestId,
        partRequestLinesFilters,
        dispatch,
      };
      afterLinesInteractionsHandle(params);
    }
    if (rectificationTimelineFilters.page === 1) {
      dispatch(getRectificationTimelineThunk({
        rectificationId,
        filters: rectificationTimelineFilters,
      }));
    } else {
      dispatch(setRectificationTimelineFilters({ ...rectificationTimelineFilters, page: 1 }));
    }
    onClose && onClose();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    const jobTypeError = errors.find((el) => el.key === 'JobType');
    const rectificationDateUpdate = errors.find((el) => el.key === 'RectificationDateUpdateUiError');
    if (jobTypeError) {
      const message = data.jobType && handleJobTypeError
        ? `${handleJobTypeError(data.jobType)} ${jobTypeError.message}`
        : jobTypeError.message;
      dispatch(setErrorMessage({ message, toastId: Math.random() }));
    }

    if (rectificationDateUpdate) {
      const { message } = rectificationDateUpdate;
      dispatch(setErrorMessage({ message, toastId: Math.random() }));
    }

    const priceDateUpdateUiError = errors.find((el) => el.key === 'PriceDateUpdateUiError');
    if (priceDateUpdateUiError) {
      const { message } = priceDateUpdateUiError;
      dispatch(setErrorMessage({ message, toastId: Math.random() }));
      setInitValue && setInitValue();
    }
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const putRectificationStatusThunk = createAsyncThunk<
ResponseSingleResult,
{
  rectificationId: number,
  statusTransition: number,
  onCloseModal?: VoidFunctionType,
  rejectionReason?: string,
}
>('put/RectificationStatus', async ({
  rectificationId, statusTransition, onCloseModal, rejectionReason,
}, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await rectificationsAPI.changeRectificationStatus(rectificationId, statusTransition, rejectionReason);
    const { rectifications: { rectificationTimelineFilters } } = getState() as RootState;
    dispatch(fetchRectificationByIdThunk({ rectificationId }));
    if (rectificationTimelineFilters.page === 1) {
      dispatch(getRectificationTimelineThunk({
        rectificationId,
        filters: rectificationTimelineFilters,
      }));
    } else {
      dispatch(setRectificationTimelineFilters({ ...rectificationTimelineFilters, page: 1 }));
    }
    onCloseModal && onCloseModal();
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    statusTransitionErrorHandle(errors, dispatch);
    onCloseModal && onCloseModal();
    return rejectWithValue(error.response?.data);
  }
});

export const getRectificationLinksThunk = createAsyncThunk<
ResponseSingleResult<RectificationLinksResType>, { id: number, filters: LinksFiltersType }
>('get/RectificationLinks', async ({
  id, filters,
}, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await rectificationsAPI.fetchRectificationLinks(id, filters);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const postRectificationTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  rectificationId: number, content: string, setValue: VoidFunctionType,
}>(
  'post/RectificationTimelineNote',
  async ({
    rectificationId, content, setValue,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.postRectificationTimelineNote(rectificationId, content);
      const {
        rectifications: { rectificationTimelineFilters },
        shared: { rectificationDetails },
      } = getState() as RootState;
      dispatch(setLoading(false));
      if (rectificationTimelineFilters.page === 1 && rectificationDetails) {
        dispatch(getRectificationTimelineThunk({
          rectificationId: rectificationDetails.rectification.id,
          filters: rectificationTimelineFilters,
        }));
      } else {
        dispatch(setRectificationTimelineFilters({ ...rectificationTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      setValue();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editRectificationTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, content: string, closeModal: VoidFunctionType,
}>(
  'put/RectificationTimelineNote',
  async ({
    id, content, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await rectificationsAPI.editRectificationTimelineNote(id, content);
      const {
        rectifications: { rectificationTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventRectification[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = rectificationTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setRectificationTimeline({ ...rectificationTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteRectificationTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/RectificationTimelineNote',
  async ({
    id, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await rectificationsAPI.deleteRectificationTimelineNote(id);
      const {
        rectifications: { rectificationTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setRectificationTimelineFilters({ ...rectificationTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsForRectificationThunk = createAsyncThunk<
ResponseResult<PartDtoType[]>,
{ filters: StockFiltersType }
>(
  'get/StockPartsForRectification',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitsForRectificationThunk = createAsyncThunk<ResponseResult<PartsKitsDto[]>, { filters: PartsKitsFiltersType }>(
  'get/PartsKitsForRectification',
  async ({ filters }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitsList(
        filters,
        true,
        true,
        true,
      );
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addPartsKitLineToRectificationThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number,
  data: CreatePartRequestPartsKitLine,
  closeModal: VoidFunctionType,
}>(
  'post/PartsKitLineToRectification',
  async ({
    partRequestId, data, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      const response = await stockApi.addPartsKitLineToPartRequest(partRequestId, data);
      const rectificationId = rectificationDetails?.rectification?.id;
      if (rectificationId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const partsKitErrors = errors.filter((err: ServerErrorType) => err.key === 'Quantity' || err.key === 'PartsKitId');
      if (partsKitErrors.length > 0) {
        dispatch(setErrorMessage({ message: partsKitErrors[0].message, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addCustomLineToRectificationThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number,
  data: CustomLineReqType,
  closeModal: VoidFunctionType,
}>(
  'post/CustomLineToRectification',
  async ({
    partRequestId, data, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      const response = await stockApi.addCustomLineToPartRequest(partRequestId, data);
      const rectificationId = rectificationDetails?.rectification?.id;
      if (rectificationId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params);
      }

      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addRectificationPartLineThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number,
  data: CommonCreatePartLineReqDto,
  closeModal: VoidFunctionType,
}>(
  'post/RectificationPartLine',
  async ({
    partRequestId, data, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      const response = await stockApi.addPartLineToPartRequest(partRequestId, data);
      const rectificationId = rectificationDetails?.rectification?.id;
      if (rectificationId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchRectificationPartRequestLineThunk = createAsyncThunk<ResponseSingleResult | null, {
  partRequestId: number,
  lineId: number,
  data: PatchPartReqLineDto,
  setFieldToEdit?: VoidFunctionType,
  closeModal?: VoidFunctionType,
  openReasonModal?: VoidFunctionType,
}>(
  'patch/RectificationPartRequestLine',
  async ({
    partRequestId, lineId, data, setFieldToEdit, closeModal, openReasonModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      if (!data.reason) {
        const {
          data: { data: { isCostChanged } },
        } = await stockApi.getPartRequestSummaryReview({ id: partRequestId, partRequestLineIds: [lineId], data });
        if (isCostChanged) {
          openReasonModal && openReasonModal();
          dispatch(setFetching(false));
          return null;
        }
      }
      const response = await stockApi.patchPartRequestLine(partRequestId, lineId, data);
      const rectificationId = rectificationDetails?.rectification?.id;
      if (rectificationId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params);
      }
      setFieldToEdit && setFieldToEdit();
      closeModal && closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const actionReasonError = errors.find((el) => el.key === 'Reason');
      if (actionReasonError) {
        const { message } = actionReasonError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        setFieldToEdit && setFieldToEdit();
      }

      const priceDateUpdateUiError = errors.find((el) => el.key === 'PriceDateUpdateUiError');
      if (priceDateUpdateUiError) {
        const { message } = priceDateUpdateUiError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        setFieldToEdit && setFieldToEdit();
      }
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchBulkRectificationPartReqLines = createAsyncThunk<
ResponseSingleResult | null,
{
  partRequestId: number,
  rectificationId: number,
  data: BulkPatchReqLinesDataType,
  closeModal: VoidFunctionType,
  openReasonModal: VoidFunctionType,
}
>(
  'patch/bulkPartReqLines',
  async ({
    partRequestId, rectificationId, data, closeModal, openReasonModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { rectifications: { partRequestLinesFilters } } = getState() as RootState;
      const params = {
        id: rectificationId,
        partRequestId,
        partRequestLinesFilters,
        dispatch,
      };
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartRequestLines({
        ...partRequestLinesFilters,
        partRequestIds: [partRequestId],
        ...emptyPaging,
      });
      const filteredIds = data.partRequestLineIds.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        afterLinesInteractionsHandle(params);
        closeModal();
        dispatch(setLoading(false));
        return null;
      }
      if (!data.patchPartRequestLineDto.reason) {
        const {
          data: { data: { isCostChanged } },
        } = await stockApi.getPartRequestSummaryReview({
          id: partRequestId,
          partRequestLineIds: filteredIds,
          data: data.patchPartRequestLineDto,
        });
        if (isCostChanged) {
          dispatch(setLoading(false));
          openReasonModal();
          return null;
        }
      }
      const newData = { ...data, partRequestLineIds: filteredIds };
      const response = await stockApi.bulkPatchPartReqLines(partRequestId, newData);
      afterLinesInteractionsHandle(params);
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const actionReasonError = errors.find((el) => el.key === 'Reason');
      if (actionReasonError) {
        const { message } = actionReasonError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        closeModal();
      }

      const priceDateUpdateUiError = errors.find((el) => el.key === 'PriceDateUpdateUiError');
      if (priceDateUpdateUiError) {
        const { message } = priceDateUpdateUiError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        closeModal();
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteRectificationPartReqSingleLineThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  rectificationId: number,
  lineId: number,
  closeModal: VoidFunctionType,
  deletionReason: string,
}
>(
  'delete/Rectification/SinglePartReqLine',
  async ({
    rectificationId, lineId, closeModal, deletionReason,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      const partRequestId = rectificationDetails?.rectification?.partRequestId;
      const response = await stockApi.deletePartReqLine(lineId, deletionReason);
      if (partRequestId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const bulkDeleteRectificationPartReqLinesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  rectificationId: number,
  partRequestId: number,
  partRequestLineIds: number[],
  reason: string,
  closeModal: VoidFunctionType,
}
>(
  'delete/Rectification/BulkPartReqLines',
  async ({
    rectificationId, partRequestId, partRequestLineIds, closeModal, reason,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { rectifications: { partRequestLinesFilters } } = getState() as RootState;
      const params = {
        id: rectificationId,
        partRequestId,
        partRequestLinesFilters,
        dispatch,
      };
      const onFinish = () => {
        afterLinesInteractionsHandle(params);
        closeModal();
        dispatch(setLoading(false));
      };
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartRequestLines({
        ...partRequestLinesFilters,
        partRequestIds: [partRequestId],
        ...emptyPaging,
      });
      const filteredList = partRequestLineIds.filter((el) => allItemIds.includes(el));
      if (!filteredList.length) {
        onFinish();
        return null;
      }
      const response = await stockApi.bulkDeletePartReqLines(filteredList, reason);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const submitRectificationPartReqLinesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  partRequestId: number,
  customLinesIds: number[] | null,
  closeModal: VoidFunctionType,
}
>(
  'put/SubmitRectificationPartReqLines',
  async ({
    partRequestId, customLinesIds, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      const response = await stockApi.submitPartReqLines(partRequestId, customLinesIds);
      const rectificationId = rectificationDetails?.rectification?.id;
      if (rectificationId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params, true);
      }
      closeModal();
      dispatch(setSuccessMessage({ message: 'The part request was successfully submitted.', toastId: Math.random() }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const discardRectificationPartReqLinesChangesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  partRequestId: number,
  closeModal: VoidFunctionType,
}
>(
  'put/discardRectificationPartReqLinesChanges',
  async ({
    partRequestId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications, shared } = getState() as RootState;
      const { partRequestLinesFilters } = rectifications;
      const { rectificationDetails } = shared;
      const response = await stockApi.discardPartReqLinesChanges(partRequestId);
      const rectificationId = rectificationDetails?.rectification?.id;
      if (rectificationId) {
        const params = {
          id: rectificationId,
          partRequestId,
          partRequestLinesFilters,
          dispatch,
        };
        afterLinesInteractionsHandle(params, true);
      }
      closeModal();
      dispatch(setSuccessMessage({ message: 'The changes were successfully discarded.', toastId: Math.random() }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
