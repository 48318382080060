import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse,
  CoreAttachListElType,
  CoreAttachmentsFilters,
  emptyPaging,
  initialCoreAttachmentsFilters,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  setCurrentArea,
  setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { routesPath } from '../../../core/enums/pathEnum';
import { jobsAPI } from '../../../api/jobsApi';
import { CreateJobReqType, CreateJobResType } from '../../../job/create-job/types/createJobTypes';
import {
  GetJobAttachmentsResponse,
  GetJobResponse,
  GetJobTimeTrackingResponse,
  JobEventDtoBase,
  JobQualityCheckDto,
  PatchJobDto,
  PostJobQualityCheckRestModel,
} from '../../../job/job-view-page/types/jobViewTypes';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import {
  setJobAttachFilters, setJobAttachments, setJobTimeline, setJobTimelineFilters,
} from '../../slices/jobsSlice';
import { RootState } from '../../store';
import { coreAPI } from '../../../api/coreAPI';
import {
  ExtendedTimelineFilters,
  PartReqLinesFilters,
  PartRequestLineDto,
} from '../../../common/types/commonTypes';
import { stockApi } from '../../../api/stockApi';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { UserEventJob } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';
import { getJobsDashboardThunk } from './jobsDashboardThunks';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export const createJobThunk = createAsyncThunk<ResponseSingleResult<CreateJobResType> | null,
{
  data: CreateJobReqType,
  navigate: NavigateFunction,
  reset: VoidFunctionType,
}
>(
  'post/Job',
  async ({
    data, navigate, reset,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.createJob(data);
      const { jobNumber } = response.data.data;
      reset();
      jobNumber && dispatch(setSuccessMessage({ message: `Job "${jobNumber}" was successfully created.` }));
      dispatch(setLoading(false));
      navigate(`/${routesPath.JOBS}/${response.data.data.createdId}`);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobTimelineThunk = createAsyncThunk<
ResponseResult<JobEventDtoBase[]>, {
  jobId: number, filters: ExtendedTimelineFilters & { notesOnly: boolean },
}>(
  'get/JobTimeline',
  async ({
    jobId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobTimeline(jobId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobByIdThunk = createAsyncThunk<ResponseSingleResult<GetJobResponse> | null,
{ id: number }
>(
  'get/JobById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const responseBa = await coreAPI.fetchBusinessAreas();
      const baList = responseBa.data.data.items;
      const allowedBA = baList.filter((el) => el.isAllowed);
      const response = await jobsAPI.fetchJobById(id);

      const { businessArea } = response.data.data.job;
      if (allowedBA.find((ba) => ba.value === businessArea)) {
        dispatch(setCurrentArea(businessArea));
      } else {
        dispatch(setCurrentArea(allowedBA[0].value));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteJobThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, onFinish: VoidFunctionType }
>(
  'delete/JobById',
  async ({ id, onFinish }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.deleteJob(id);
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      onFinish();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeJobDetailsThunk = createAsyncThunk<ResponseSingleResult | null,
{
  id: number,
  patchData: PatchJobDto,
  closeModal?: VoidFunctionType,
  refetch?: VoidFunctionType,
}
>(
  'patch/JobDetails',
  async ({
    id, patchData, closeModal, refetch,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.changeJobDetails(id, patchData);
      const { jobs: { jobTimelineFilters } } = getState() as RootState;
      dispatch(getJobByIdThunk({ id }));
      dispatch(setJobTimelineFilters({ ...jobTimelineFilters, page: 1 }));
      closeModal && closeModal();
      refetch && refetch();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobTimeTracking = createAsyncThunk<
ResponseSingleResult<GetJobTimeTrackingResponse>,
{ id: number }
>('get/JobTimeTracking', async ({ id }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await jobsAPI.fetchJobTimeTracking(id);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const changeJobStatusThunk = createAsyncThunk<ResponseSingleResult | null,
{
  id: number,
  statusTransition: number,
  list?: CoreAttachListElType[],
  comment?: string,
  setDefaultStatus: VoidFunctionType,
  onClose: VoidFunctionType,
  triggerProceedToQualityCheckModal?: VoidFunctionType
}
>(
  'put/JobStatus',
  async ({
    id, statusTransition, list, comment, setDefaultStatus, onClose, triggerProceedToQualityCheckModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      if (list && list.length > 0) {
        const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));

        const resUploadUri = await jobsAPI.postJobAttachmentUploadUri(id, uploadUriArr);
        const attachments = await uploadAttachmentsAsyncHandle(
          list,
          comment || '',
          resUploadUri,
          dispatch,
        );

        await jobsAPI.postJobAttachments(id, attachments);
        dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      }
      const response = await jobsAPI.changeJobStatus(id, statusTransition);
      const {
        jobs: { jobTimelineFilters },
        account: { currentUser },
      } = getState() as RootState;
      const currentUserPermissions = currentUser?.userContextRole.permissions;

      dispatch(setJobTimelineFilters({ ...jobTimelineFilters, page: 1 }));
      dispatch(getJobByIdThunk({ id }));
      triggerProceedToQualityCheckModal && triggerProceedToQualityCheckModal();

      if (currentUserPermissions && currentUserPermissions.includes(PermissionEnum.JobTimeTracking)) {
        dispatch(getJobTimeTracking({ id }));
      }

      dispatch(setLoading(false));
      onClose();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      setDefaultStatus();
      onClose();
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeJobAssigneesThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, assigneeIds: number[] }
>(
  'put/JobAssignees',
  async ({ id, assigneeIds }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.changeJobAssignees(id, assigneeIds);
      const {
        jobs: { jobTimelineFilters },
        account: { currentUser },
      } = getState() as RootState;
      dispatch(setJobTimelineFilters({ ...jobTimelineFilters, page: 1 }));
      dispatch(getJobByIdThunk({ id }));

      const currentUserPermissions = currentUser?.userContextRole.permissions;

      if (currentUserPermissions && currentUserPermissions.includes(PermissionEnum.JobTimeTracking)) {
        dispatch(getJobTimeTracking({ id }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobAttachmentsThunk = createAsyncThunk<GetJobAttachmentsResponse,
{ id: number, params: CoreAttachmentsFilters }
>(
  'get/JobAttachments',
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobAttachments(id, params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobAttachmentByIdThunk = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ id: number, jobId: number }
>(
  'get/JobAttachmentById',
  async ({ id, jobId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobAttachmentById(id, jobId);
      downloadCsv(response.data.data.uri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createJobAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  id: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
  fileAttachmentType?: number,
}
>('post/JobAttachment', async ({
  id, comment, list, onCancel, fileAttachmentType,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { jobs } = getState() as RootState;
    const { jobAttachmentsFilters } = jobs;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await jobsAPI.postJobAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
      true,
      fileAttachmentType,
    );
    const finalRes = await jobsAPI.postJobAttachments(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getJobByIdThunk({ id }));
    dispatch(getJobAttachmentsThunk({ id, params: jobAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultipleJobAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  jobId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleJobAttachments',
  async ({
    ids, jobId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { jobs: { jobAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allOwnItemIds } } } = await jobsAPI.fetchJobAttachments(jobId, {
        ...jobAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allOwnItemIds.includes(id));
      if (filteredIds.length) {
        await jobsAPI.deleteMultipleJobAttachment(jobId, filteredIds);
      }
      dispatch(setJobAttachFilters({ ...jobAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getJobByIdThunk({ id: jobId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteJobAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, jobId: number, closeModal: VoidFunctionType, }>(
  'delete/JobAttachment',
  async ({
    id, jobId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.deleteJobAttachment(jobId, id);
      dispatch(getJobAttachmentsThunk({
        id: jobId,
        params: initialCoreAttachmentsFilters,
      }));
      dispatch(setJobAttachFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getJobByIdThunk({ id: jobId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editJobAttachment = createAsyncThunk<
ResponseSingleResult,
{ id: number, jobId: number, comment: string, onCancel: VoidFunctionType }
>('patch/JobAttachment', async ({
  id, jobId, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { jobs } = getState() as RootState;
    const { jobAttachments } = jobs;
    const response = await jobsAPI.editJobAttachment(jobId, id, comment);
    const newArr = jobAttachments.items.map((item) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setJobAttachments({ ...jobAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const postJobTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  jobId: number, content: string, setValue: VoidFunctionType,
}>(
  'post/JobTimelineNote',
  async ({
    jobId, content, setValue,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.postJobTimelineNote(jobId, content);
      const { jobs: { jobTimelineFilters } } = getState() as RootState;
      dispatch(setLoading(false));
      dispatch(setJobTimelineFilters({ ...jobTimelineFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      setValue();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editJobTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, content: string, closeModal: VoidFunctionType,
}>(
  'put/JobTimelineNote',
  async ({
    id, content, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await jobsAPI.editJobTimelineNote(id, content);
      const {
        jobs: { jobTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventJob[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = jobTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setJobTimeline({ ...jobTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteJobTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/JobTimelineNote',
  async ({
    id, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await jobsAPI.deleteJobTimelineNote(id);
      const {
        jobs: { jobTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters, page: 1 }));
      } else {
        dispatch(setJobTimelineFilters({ ...jobTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobPartLinesThunk = createAsyncThunk<ResponseResult<PartRequestLineDto[]>, {
  partRequestId: number, filters?: PartReqLinesFilters,
}>(
  'get/JobPartLines',
  async ({ partRequestId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestLines({ ...filters, partRequestIds: [partRequestId] }, true);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobPartLinesForQualityCheckModalThunk = createAsyncThunk<ResponseResult<PartRequestLineDto[]>, {
  partRequestId: number, params?: PartReqLinesFilters,
}>(
  'get/JobPartLinesForQualityCheckModal',
  async ({ partRequestId, params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestLines(
        { ...params, partRequestIds: [partRequestId] },
        true,
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobQualityChecksThunk = createAsyncThunk<ResponseSingleResult<{ qualityChecks: JobQualityCheckDto[] }>,
{ jobId: number }
>(
  'get/JobQualityChecks',
  async ({ jobId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.getJobQualityChecks(jobId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createJobQualityCheckThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>,
{ jobId: number, data: PostJobQualityCheckRestModel, closeModal: VoidFunctionType, dashboardMode?: boolean }
>(
  'post/JobQualityCheck',
  async ({
    jobId, data, closeModal, dashboardMode,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { jobs: { filters } } = getState() as RootState;
      const response = await jobsAPI.createJobQualityCheck(jobId, data);
      closeModal();
      if (dashboardMode) {
        dispatch(getJobsDashboardThunk({ filters }));
      } else {
        dispatch(getJobByIdThunk({ id: jobId }));
        dispatch(getJobQualityChecksThunk({ jobId }));
      }
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteJobQualityCheckThunk = createAsyncThunk<ResponseSingleResult,
{ jobId: number, checkId: number, closeModal: VoidFunctionType }
>(
  'delete/JobQualityCheck',
  async ({ jobId, checkId, closeModal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.deleteJobQualityCheck(jobId, checkId);
      closeModal();
      dispatch(getJobByIdThunk({ id: jobId }));
      dispatch(getJobQualityChecksThunk({ jobId }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
