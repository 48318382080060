import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  partRequestLinePriceSourcesSelector,
  pricelistPriceSourcesSelector,
  QuoteInfoSourcesSelector,
  supplyOnlyJobTypesSelector,
  usersFilterLookupSelector,
  vehicleInfoSourcesSelector,
} from './coreSelectors';
import { QuoteInfoSourcesUiNames, VehicleInfoSourcesUiNames } from '../../core/enums/dictionariesEnums';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import {
  NOT_ALLOCATED_COLUMNS,
  NOT_COLLECTED_COLUMNS,
  NOT_READY_COLUMNS,
} from '../../stock/stock-part-request-view/utils/data';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { StockPartReqLineOrderingEnum } from '../../stock/stock-part-request-view/enums/StockPartRequestViewPageEnums';
import {
  StockPartReqAllStatusEnum,
  StockPartReqStatusesEnum,
} from '../../stock/stock-part-requests/enums/stockPartRequestsEnum';
import { customersDictionaryLookupSelector, partCategoriesLookupSelector } from './sharedSelectors';
import { summaryFieldValueHandle } from '../../core/utils/summaryFieldValueHandle';
import {
  allocatedPartReqLinesStatusesSelector,
  orderStatusSelector,
  partRequestLineStatusesSelector,
  partRequestStatusesUiSelector,
  partRequestStatusObjSelector,
  partQtyStatusesValuesSelector,
  rectificationStatusesSelector,
} from './coreStatusesSelectors';
import {
  adjustPartQtyOrderingSelector,
  confirmedPartRequestsOrderingSelector,
  stockPartRequestLinesOrderingSelector,
} from './coreOrderingSelectors';
import { PartRequestLineDto } from '../../common/types/commonTypes';
import { EditReadyCollectedUiTableData } from '../../stock/part-view-page/types/types';
import { StockPartReqTableLineType } from '../../stock/stock-part-requests/types/stockPartRequestTypes';

const editReadyCollectedModalAvailablePurchaseOrdersInitSelector = (state: RootState) => {
  return state.stockPartRequest.editReadyCollectedModalAvailablePurchaseOrders;
};
export const editReadyCollectedModalAvailablePurchaseOrdersSelector = createSelector(
  editReadyCollectedModalAvailablePurchaseOrdersInitSelector,
  (list) => {
    return list?.map((p) => ({
      value: p.purchaseOrderId || 'null',
      label: p.purchaseOrderNumber || '(Blank)',
    }));
  },
);

export const stockPartRequestsLinesViewFiltersSelector = (state: RootState) => state.stockPartRequest.stockPartRequestsLinesViewFilters;
export const stockPartRequestsLinesViewFiltersListSelector = createSelector(
  stockPartRequestsLinesViewFiltersSelector,
  customersDictionaryLookupSelector,
  partRequestLineStatusesSelector,
  (filters, customers, statuses) => {
    return [
      {
        value: filters.statuses || [],
        name: 'statuses',
        label: 'Status',
        noSearch: true,
        options: statuses,
      },
    ];
  },
);
export const stockPartRequestsFiltersSelector = (state: RootState) => state.stockPartRequest.stockPartRequestsFilters;
export const stockPartRequestDashboardSelector = (state: RootState) => state.stockPartRequest.stockPartRequestDashboard;

export const stockPartReqStatusWithCountSelector = createSelector(
  partRequestStatusesUiSelector,
  stockPartRequestDashboardSelector,
  stockPartRequestsFiltersSelector,
  partRequestStatusObjSelector,
  (statuses, data, filters, statusObj) => {
    const { archived } = statusObj;
    let allTotalActive = 0;
    data?.statusCounts?.forEach((el) => {
      if (el.status !== archived) allTotalActive += el.count;
    });

    const allTotalActiveLabel = allTotalActive
      ? `(${allTotalActive})`
      : '';

    const getStatusCount = (status: number) => {
      const count = data?.statusCounts?.find((p) => p.status === status)?.count || 0;
      return count ? `(${count.toString()})` : '';
    };

    const newItems = statuses.map((item) => ({
      ...item,
      label: `${item.label} ${getStatusCount(item.value)}`,
    }));
    return [
      { label: `All ${allTotalActiveLabel}`, value: StockPartReqAllStatusEnum.ALL },
      ...newItems,
    ];
  },
);

export const stockPartReqActiveStatusesSelector = createSelector(stockPartReqStatusWithCountSelector, (statuses) => {
  return statuses.filter((st) => !st.label.includes(StockPartReqStatusesEnum.Archived));
});

export const stockPartReqArchivedStatusesSelector = createSelector(stockPartReqStatusWithCountSelector, (statuses) => {
  return statuses.filter((st) => st.label.includes(StockPartReqStatusesEnum.Archived));
});

export const stockPartRequestLinkedEntitiesTypesSelector = (state: RootState) => state.stockPartRequest.stockPartRequestLinkedEntitiesTypes;
export const stockPartRequestDashboardUiSelector = createSelector(
  stockPartRequestDashboardSelector,
  stockPartRequestLinkedEntitiesTypesSelector,
  orderStatusSelector,
  rectificationStatusesSelector,
  partRequestStatusesUiSelector,
  (
    data,
    entityTypes,
    orderStatuses,
    rectificationStatuses,
    partReqStatuses,
  ) => {
    const getEntityStatus = (type: number, status: number) => {
      const currentType = entityTypes.find((el) => el.value === type)?.label;
      if (currentType === 'Order') {
        return orderStatuses.find((el) => el.value === status)?.label;
      } else {
        return rectificationStatuses.find((el) => el.value === status)?.label;
      }
    };
    const newItems = data.items.map((el) => ({
      ...el,
      linkedEntityStatus: getEntityStatus(el.linkedEntityType, el.linkedEntityStatus),
      status: partReqStatuses.find((st) => st.value === el.status)?.label,
    }));
    return {
      ...data,
      items: newItems,
    };
  },
);

export const stockPartRequestDetailsSelector = (state: RootState) => state.stockPartRequest.stockPartRequestDetails;
const stockPartReqVehicleDataSelector = createSelector(stockPartRequestDetailsSelector, (details) => {
  const vehicleLocationDraft = {
    vehicleCompanyName: details?.vehicleCompanyName,
    vehicleAddressLine1: details?.vehicleAddressLine1,
    vehicleAddressLine2: details?.vehicleAddressLine2,
    vehicleCityTown: details?.vehicleCityTown,
    vehicleRegion: details?.vehicleRegion,
    vehiclePostalCode: details?.vehiclePostalCode,
    vehicleTelephone: details?.vehicleTelephone,
  };

  return {
    manufacturer: details?.manufacturer,
    modelDescription: details?.modelDescription,
    regNumber: details?.regNumber,
    chassisNumber: details?.chassisNumber,
    vehicleLocation: summaryFieldValueHandle(Object.values(vehicleLocationDraft)),
    vehicleInfoSource: details?.vehicleInfoSource,
    vehicleProfileCode: details?.vehicleProfileCode,
    vehicleProfileType: details?.vehicleProfileType,
    vehicleProfileGroup: details?.vehicleProfileGroup,
    slidingDoorsQuantity: details?.slidingDoorsQuantity,
  };
});
export const stockPartRequestVehicleUiSelector = createSelector(
  stockPartReqVehicleDataSelector,
  (vehicle) => {
    const manufacturer = vehicle.manufacturer || '';
    const modelDescription = vehicle.modelDescription || '';
    const specificInfo = {
      regNumber: vehicle.regNumber,
      chassisNumber: vehicle.chassisNumber,
      location: vehicle.vehicleLocation,
    };

    return { name: manufacturer, info: modelDescription, specificInfo };
  },
);

export const isStockPartReqJobTypeSupplySelector = createSelector(
  stockPartRequestDetailsSelector,
  supplyOnlyJobTypesSelector,
  (details, supplyOnlyJobTypes) => {
    return supplyOnlyJobTypes.includes(details?.jobType);
  },
);
export const isPartReqVehManualSelector = createSelector(
  vehicleInfoSourcesSelector,
  stockPartReqVehicleDataSelector,
  (sources, veh) => {
    const manualSource = sources.find((el) => el.label === VehicleInfoSourcesUiNames.ManualInput);
    return manualSource?.value === veh.vehicleInfoSource;
  },
);

export const stockPartRequestsLinesViewInitSelector = (state: RootState) => state.stockPartRequest.stockPartRequestsLinesView;
export const stockPartRequestsLinesViewTableSelector = createSelector(
  stockPartRequestsLinesViewInitSelector,
  stockPartRequestsLinesViewFiltersSelector,
  partRequestLineStatusesSelector,
  allocatedPartReqLinesStatusesSelector,
  (data, filters, statuses, allocatedStatuses) => {
    const isPartsKitParent = (partsKitId: number | null, childLinesLength: number | undefined) => partsKitId
      && childLinesLength
      && childLinesLength > 0;
    const getReadyCollected = (status: number, quantity: number) => {
      const isAllocated = !!allocatedStatuses.find((st) => st.value === status);
      return isAllocated ? quantity : quantity || '-';
    };

    return {
      ...data,
      items: data.items.map((el, i) => {
        return {
          id: el.id,
          ordinalNumber: getNo(filters.page, filters.pageSize, i),
          customer: el.customerName || '-',
          partRequestNumber: el.partRequestNumber || '-',
          linkedEntityNumber: el.linkedEntityNumber || '-',
          linkedEntityStatus: el.linkedEntityStatusString || '-',
          categoryName: el.categoryName || '-',
          productNumber: el.productNumber || '-',
          productName: el.productName || '-',
          notes: el.notes || '-',
          priceSources: el.priceSources || '-',
          quantity: el.requestedQuantity,
          readyQuantity: isPartsKitParent(el.partsKitId, el.childLines?.length)
            ? ''
            : getReadyCollected(el.status, el.readyQuantity),
          collectedQuantity: isPartsKitParent(el.partsKitId, el.childLines?.length)
            ? ''
            : getReadyCollected(el.status, el.collectedQuantity),
          status: isPartsKitParent(el.partsKitId, el.childLines?.length)
            ? ''
            : statuses.find((st) => st.value === el.status)?.label || '',
          unitOfMeasure: el.unitOfMeasure,
          partsKitId: el.partsKitId,
          partId: el.partId,
          partsKitComponentId: el.partsKitComponentId,
          partsKitComponentPartId: el.partsKitComponentPartId,
          canPurchaseOrderBeGenerated: el.canPurchaseOrderBeGenerated,
          deletionReason: el.deletionReason,
          childLines: el.childLines?.map((ch, childIndex) => ({
            id: ch.id,
            ordinalNumber: `${getNo(filters.page, filters.pageSize, i)}.${childIndex + 1}`,
            customer: ch.customerName || '-',
            partRequestNumber: ch.partRequestNumber || '-',
            linkedEntityNumber: ch.linkedEntityNumber || '-',
            linkedEntityStatus: ch.linkedEntityStatusString || '-',
            categoryName: el.categoryName || '-',
            productNumber: ch.productNumber || '-',
            productName: ch.productName || '-',
            notes: ch.notes || '-',
            priceSources: ch.priceSources || '-',
            quantity: ch.requestedQuantity,
            readyQuantity: getReadyCollected(ch.status, ch.readyQuantity),
            collectedQuantity: getReadyCollected(ch.status, ch.collectedQuantity),
            status: statuses.find((st) => st.value === ch.status)?.label || '-',
            unitOfMeasure: ch.unitOfMeasure,
            partsKitId: ch.partsKitId,
            partId: ch.partId,
            partsKitComponentId: ch.partsKitComponentId,
            partsKitComponentPartId: ch.partsKitComponentPartId,
            canPurchaseOrderBeGenerated: ch.canPurchaseOrderBeGenerated,
            deletionReason: ch.deletionReason,
          })) ?? [],
        };
      }),
    };
  },
);

export const stockPartRequestLinesSelector = (state: RootState) => state.stockPartRequest.stockPartRequestLines;
export const stockPartReqColumnsSelector = createSelector(
  partCategoriesLookupSelector,
  partRequestLinePriceSourcesSelector,
  partRequestLineStatusesSelector,
  stockPartRequestLinesOrderingSelector,
  stockPartRequestDetailsSelector,
  (categories, priceSources, lineStatuses, ordering, details) => {
    const isHasNotes = details?.enableNotes;
    const anyLineLinkedToPurchaseOrder = details?.anyLineLinkedToPurchaseOrder;

    let newCols = [
      {
        title: 'No',
        dataIndex: 'ordinalNumber',
        sorter: true,
        orderField: getSortIndex(StockPartReqLineOrderingEnum.OrdinalNumber, ordering),
      },
      {
        title: 'Category',
        dataIndex: 'categoryId',
        sorter: true,
        orderField: getSortIndex(StockPartReqLineOrderingEnum.Category, ordering),
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(StockPartReqLineOrderingEnum.ProductNumber, ordering),
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(StockPartReqLineOrderingEnum.ProductName, ordering),
      },
      {
        title: 'Purchase order No',
        dataIndex: 'purchaseOrderNumber',
        filterSearch: true,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        filterSearch: true,
      },
      {
        title: 'Source',
        dataIndex: 'priceSources',
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
      },
      {
        title: 'Ready',
        dataIndex: 'readyQuantity',
      },
      {
        title: 'Collected',
        dataIndex: 'collectedQuantity',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: true,
        orderField: getSortIndex(StockPartReqLineOrderingEnum.Status, ordering),
      },
    ];
    if (!isHasNotes) {
      newCols = newCols.filter((col) => col.dataIndex !== 'notes');
    }
    if (!anyLineLinkedToPurchaseOrder) {
      newCols = newCols.filter((col) => col.dataIndex !== 'purchaseOrderNumber');
    }
    return newCols.map((col) => {
      switch (col.dataIndex) {
        case 'categoryId':
          return { ...col, filters: categories };
        case 'priceSources':
          return { ...col, filters: priceSources };
        case 'status':
          return { ...col, filters: lineStatuses };
        default: return col;
      }
    });
  },
);

export const stockPartRequestLinesFiltersSelector = (state: RootState) => state.stockPartRequest.stockPartRequestLinesFilters;
export const stockPartReqTableLinesSelector = createSelector(
  stockPartRequestLinesSelector,
  partCategoriesLookupSelector,
  partRequestLineStatusesSelector,
  allocatedPartReqLinesStatusesSelector,
  stockPartRequestDetailsSelector,
  (list, stockCategories, statuses, allocatedStatuses, details) => {
    const isHasNotes = details?.enableNotes;
    const anyLineLinkedToPurchaseOrder = details?.anyLineLinkedToPurchaseOrder;
    const isPartsKitParent = (el: PartRequestLineDto) => el.partsKitId && el.childLines && el.childLines.length > 0;
    const getReadyCollected = (el: PartRequestLineDto, quantity: number) => {
      const isAllocated = !!allocatedStatuses.find((st) => st.value === el.status);
      return isAllocated ? quantity : quantity || '-';
    };
    let lines: StockPartReqTableLineType[] = list.items.map((el) => ({
      id: el.id,
      ordinalNumber: el.ordinalNumber,
      categoryId: stockCategories.find((cat) => el.categoryId === cat.value)?.label,
      productNumber: el.productNumber || '-',
      productName: el.productName || '-',
      purchaseOrderNumber: el.purchaseOrders || [],
      notes: el.notes || '-',
      priceSources: el.priceSources || '-',
      quantity: el.requestedQuantity,
      readyQuantity: isPartsKitParent(el) ? '' : getReadyCollected(el, el.readyQuantity),
      collectedQuantity: isPartsKitParent(el) ? '' : getReadyCollected(el, el.collectedQuantity),
      allocatedQuantity: el.allocatedQuantity || '0',
      status: isPartsKitParent(el)
        ? ''
        : statuses.find((st) => st.value === el.status)?.label || '',
      unitOfMeasure: el.unitOfMeasure,
      partsKitId: el.partsKitId,
      partId: el.partId,
      partsKitComponentId: el.partsKitComponentId,
      partsKitComponentPartId: el.partsKitComponentPartId,
      deletionReason: el.deletionReason,
      childLines: el.childLines
        ? el.childLines.map((ch) => ({
          id: ch.id,
          ordinalNumber: `${el.ordinalNumber}.${ch.ordinalNumber}`,
          categoryId: stockCategories.find((cat) => ch.categoryId === cat.value)?.label,
          productNumber: ch.productNumber || '-',
          productName: ch.productName || '-',
          purchaseOrderNumber: ch.purchaseOrders || [],
          notes: ch.notes || '-',
          priceSources: ch.priceSources || '-',
          quantity: ch.requestedQuantity,
          readyQuantity: getReadyCollected(ch, ch.readyQuantity),
          collectedQuantity: getReadyCollected(ch, ch.collectedQuantity),
          allocatedQuantity: el.allocatedQuantity || 0,
          status: statuses.find((st) => st.value === ch.status)?.label || '-',
          unitOfMeasure: ch.unitOfMeasure,
          partsKitId: ch.partsKitId,
          partId: ch.partId,
          partsKitComponentId: ch.partsKitComponentId,
          partsKitComponentPartId: ch.partsKitComponentPartId,
          deletionReason: el.deletionReason,
        }))
        : [],
    }));
    if (!isHasNotes) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      lines = lines.map(({ notes, childLines, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { ...rest, childLines: childLines.map(({ notes, ...rest }) => rest) };
      });
    }
    if (!anyLineLinkedToPurchaseOrder) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      lines = lines.map(({ purchaseOrderNumber, childLines, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { ...rest, childLines: childLines.map(({ purchaseOrderNumber, ...rest }) => rest) };
      });
    }
    return lines;
  },
);

export const stockPartProductSourceTotalSelector = (state: RootState) => state.stockPartRequest.stockPartProductSourceTotal;
export const editReadyCollectedModalQuantitiesSelector = (state: RootState) => state.stockPartRequest.editReadyCollectedModalQuantities;
export const editReadyCollectedModalFiltersSelector = (state: RootState) => state.stockPartRequest.editReadyCollectedModalFilters;
export const editReadyCollectedColumnsSelector = createSelector(
  editReadyCollectedModalFiltersSelector,
  partQtyStatusesValuesSelector,
  editReadyCollectedModalAvailablePurchaseOrdersSelector,
  adjustPartQtyOrderingSelector,
  usersFilterLookupSelector,
  (filters, qtyStatuses, purchaseOrders, ordering, potentialOwners) => {
    const { allocated, picked, collected } = qtyStatuses;
    let newCols = [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Source',
        dataIndex: 'priceSource',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Location',
        dataIndex: 'locationComment',
      },
      {
        title: 'Cost price',
        dataIndex: 'costPrice',
      },
      {
        title: 'Added date',
        dataIndex: 'addedDate',
        sorter: true,
        orderField: getSortIndex('AddedDate', ordering),
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        filters: potentialOwners || [],
      },
      {
        title: 'Collection date',
        dataIndex: 'collectionDate',
        sorter: true,
        orderField: getSortIndex('CollectionDate', ordering),
      },
      {
        title: 'Purchase order No',
        dataIndex: 'purchaseOrderNumber',
        filters: purchaseOrders || [],
      },
    ];
    if (filters.statuses?.find((el) => el === allocated)) {
      newCols = newCols.filter((col) => !NOT_ALLOCATED_COLUMNS.includes(col.dataIndex));
    }
    if (filters.statuses?.find((el) => el === picked)) {
      newCols = newCols.filter((col) => !NOT_READY_COLUMNS.includes(col.dataIndex));
    }
    if (filters.statuses?.find((el) => el === collected)) {
      newCols = newCols.filter((col) => !NOT_COLLECTED_COLUMNS.includes(col.dataIndex));
    }
    return newCols;
  },
);

export const editReadyCollectedTableLinesSelector = createSelector(
  editReadyCollectedModalQuantitiesSelector,
  editReadyCollectedModalFiltersSelector,
  partQtyStatusesValuesSelector,
  partRequestLinePriceSourcesSelector,
  (data, filters, qtyStatuses, priceSources) => {
    const { allocated, picked, collected } = qtyStatuses;
    let lines: EditReadyCollectedUiTableData[] = data?.partQuantities?.items?.map((el, i) => ({
      id: el.id,
      number: getNo(filters.page, filters.pageSize, i),
      priceSource: priceSources.find((s) => s.value === el.source)?.label || '-',
      quantity: el.quantity || 0,
      locationComment: el.locationComment || '-',
      costPrice: el.costPrice ? `£ ${el.costPrice}` : '0.00',
      addedDate: el.addedDate ? localDateFormatHandler('DD/MM/YYYY', el.addedDate) : '-',
      owner: el.owner ? `${el.owner.firstName || ''} ${el.owner.lastName || ''}` : '-',
      collectionDate: el.collectionDate ? localDateFormatHandler('DD/MM/YYYY', el.collectionDate) : '-',
      purchaseOrderNumber: el.purchaseOrderNumber || '-',
    })) || [];
    if (filters.statuses?.find((el) => el === allocated)) {
      lines = lines.map(({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        locationComment, owner, collectionDate, ...rest
      }) => rest);
    }
    if (filters.statuses?.find((el) => el === picked)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      lines = lines.map(({ owner, collectionDate, ...rest }) => rest);
    }
    if (filters.statuses?.find((el) => el === collected)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      lines = lines.map(({ addedDate, purchaseOrderNumber, ...rest }) => rest);
    }
    return lines;
  },
);

export const allocatedPartQuantitiesSelector = (state: RootState) => state.stockPartRequest.allocatedPartQuantities;
export const allocatedPartQuantitiesUiSelector = createSelector(allocatedPartQuantitiesSelector, (data) => {
  const itemsList = data.totalCount > 0
    ? data.items?.map((el) => ({
      id: el.id,
      quantity: '',
      remaining: el.quantity,
      costPrice: `£ ${el.costPrice}`,
      sellingPrice: `£ ${el.sellingPrice}`,
      addedDate: localDateFormatHandler('DD/MM/YYYY', el.addedDate),
      purchaseOrderNumber: el.purchaseOrderNumber || '-',
    }))
    : [];
  return {
    ...data,
    items: itemsList,
  };
});

export const coreNonCoreInfoSourcesSelector = createSelector(QuoteInfoSourcesSelector, (sources) => {
  const core = sources?.find((el) => el.label === QuoteInfoSourcesUiNames.CorePart);
  const nonCore = sources?.find((el) => el.label === QuoteInfoSourcesUiNames.NonCorePart);
  return core && nonCore
    ? [
      { label: core.label, value: core.value },
      { label: nonCore.label, value: nonCore.value },
    ]
    : [];
});

export const stockPartsToFillPartRequestSelector = (state: RootState) => state.stockPartRequest.stockPartsToFillPartRequest;
export const stockPartsToFillPartRequestFiltersSelector = (state: RootState) => state.stockPartRequest.stockPartsToFillPartRequestFilters;

export const stockPartRequestTimelineFiltersSelector = (state: RootState) => state.stockPartRequest.stockPartRequestTimelineFilters;
export const stockPartRequestTimelineSelector = (state: RootState) => state.stockPartRequest.stockPartRequestTimeline;

export const generatePurchaseOrderColumnsSelector = createSelector(stockPartRequestLinesOrderingSelector, (ordering) => {
  return [
    {
      title: 'Supplier',
      dataIndex: 'suppliers',
    },
    {
      title: 'Product number',
      dataIndex: 'productNumber',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.ProductNumber, ordering),
    },
    {
      title: 'Product name',
      dataIndex: 'productName',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.ProductName, ordering),
    },
    {
      title: 'Source',
      dataIndex: 'sourceId',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      sorter: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.Quantity, ordering),
    },
  ];
});

export const generatePurchaseOrderFiltersSelector = (state: RootState) => state.stockPartRequest.generatePurchaseOrderFilters;
// eslint-disable-next-line max-len
const partRequestLinesForPurchaseOrderGenerationSelector = (state: RootState) => state.stockPartRequest.partRequestLinesForPurchaseOrderGeneration;
export const partRequestLinesForPurchaseOrderGenerationTableSelector = createSelector(
  partRequestLinesForPurchaseOrderGenerationSelector,
  pricelistPriceSourcesSelector,
  (data, pricelistSource) => {
    return {
      ...data,
      items: data.items.map((item) => ({
        id: item.id,
        suppliers: item.suppliers?.length ? item.suppliers.join(', ') : '-',
        productNumber: item.productNumber || '-',
        productName: item.productName || '-',
        sourceId: [pricelistSource],
        quantity: item.purchaseOrderGenerationQuantity ?? 0,
      })),
    };
  },
);

export const confirmedPartRequestViewsParamsSelector = (state: RootState) => state.stockPartRequest.confirmedPartRequestViewsParams;
export const confirmedPartRequestColumnsSelector = createSelector(confirmedPartRequestsOrderingSelector, (ordering) => {
  return [
    {
      title: 'Supplier',
      dataIndex: 'supplierName',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex('SupplierName', ordering),
    },
    {
      title: 'Part No',
      dataIndex: 'partNumber',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex('PartNumber', ordering),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: true,
      orderField: getSortIndex('Quantity', ordering),
    },
    {
      title: 'Part requests',
      dataIndex: 'partRequestNumbers',
      filterSearch: true,
    },
    {
      title: 'Linked to',
      dataIndex: 'linkedNumbers',
      filterSearch: true,
    },
  ];
});

const initStockPartRequestsViewsConfirmedSelector = (state: RootState) => state.stockPartRequest.stockPartRequestsViewsConfirmed;
export const stockPartRequestsViewsConfirmedSelector = createSelector(initStockPartRequestsViewsConfirmedSelector, (data) => {
  return {
    ...data,
    items: data.items.map((item) => ({
      id: item.id,
      supplierName: item.supplierName || '-',
      partNumber: item.partNumber || '-',
      quantity: item.quantity,
      partRequestNumbers: item.partRequestNumbers ? item.partRequestNumbers.join(', ') : '-',
      linkedNumbers: item.linkedNumbers ? item.linkedNumbers.join(', ') : '-',
    })),
  };
});
